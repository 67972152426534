<template>
  <div>
    <b-overlay
      :show="isBusy"
      variant="transparent"
      opacity="0.85"
      rounded="sm"
      blur="5px"
    >
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            分站驗證
          </h4>
        </b-card-header>

        <!-- 信箱驗證 -->
        <b-card-body class="pb-0">
          <div class="kyc-card">
            <div class="d-flex justify-content-between flex-row w-100 align-items-center">
              <div class="d-flex justify-content-start mr-1">
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/customer/email.svg')"
                    height="45"
                    rounded
                  />
                </div>

                <div class="ml-1">
                  <h4 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                    信箱驗證
                    <b-img
                      v-if="adminBranchState.branchInfo.mail_api"
                      v-b-tooltip.hover.v-secondary
                      :title="adminBranchState.branchInfo.mail_api_info.name"
                      :src="require('@/assets/images/pages/branch/lock-bind.svg')"
                      height="18"
                      rounded
                      class="ml-50"
                      style="margin-top: -5px;"
                    />
                  </h4>

                  <small>
                    檢查信箱是否可正常接收郵件
                    <!-- {{ adminBranchState.customerInfo.account }} -->
                  </small>
                </div>
              </div>

              <div v-if="!adminBranchState.isBusyLoading">
                <b-alert
                  :variant="ui.switchStateList[2][adminBranchState.branchInfo.mail_valid_switch]"
                  show
                  class="mt-1"
                >
                  <div class="alert-body text-nowrap">
                    <span>{{ ui.switchStateList[0][adminBranchState.branchInfo.mail_valid_switch] }}</span>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
        </b-card-body>

        <!-- 手機驗證 -->
        <b-card-body>
          <div class="kyc-card">
            <div class="d-flex justify-content-between flex-row w-100 align-items-center">
              <div class="d-flex justify-content-start mr-1">
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/customer/security.svg')"
                    height="45"
                    rounded
                  />
                </div>

                <div class="ml-1">
                  <h4 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                    手機驗證
                  </h4>

                  <small>
                    確認手機號碼是否有效
                  </small>
                </div>
              </div>

              <div v-if="!adminBranchState.isBusyLoading">
                <b-alert
                  :variant="ui.switchStateList[2][adminBranchState.branchInfo.phone_valid_switch]"
                  show
                  class="mt-1"
                >
                  <div class="alert-body text-nowrap">
                    <span>{{ ui.switchStateList[0][adminBranchState.branchInfo.phone_valid_switch] }}</span>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BImg, VBTooltip, BCardHeader, BCardBody, BOverlay, BAlert,
} from 'bootstrap-vue'
import { useBranchSetting } from '../../useBranch'

export default {
  components: {
    BCard,
    BImg,
    BCardHeader,
    BCardBody,
    BOverlay,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isBusy: false,
    }
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
  setup() {
    const {
      ui,
    } = useBranchSetting()

    return {
      ui,
    }
  },
}
</script>

<style lang="scss" scoped>
.kyc-card {
  padding: 20px;
  border: 1px solid #b6b4bf47;
  border-radius: 10px;
  .kyc-card-btns {
    white-space: nowrap;
    .actions-link-btn-image {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
