<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          分站驗證
        </h4>

        <div class="text-nowrap d-flex justify-content-end">
          <div
            v-b-tooltip.hover.focus.v-secondary
            title="重新整理"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="resetBranchData"
          >
            <b-img
              src="/dashboard/admin/images/table/refresh.svg"
              class="actions-link-btn-image"
            />
          </div>

          <div
            v-b-tooltip.hover.focus.v-secondary
            title="儲存"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="submitBranchUpdate"
          >
            <b-img
              src="/dashboard/admin/images/table/save.svg"
              class="actions-link-btn-image"
            />
          </div>
        </div>
      </b-card-header>

      <!-- 信箱驗證 -->
      <b-card-body class="pb-0">
        <div class="kyc-card">
          <div class="d-flex justify-content-between flex-row w-100 align-items-center py-75">
            <div class="d-flex justify-content-start mr-1">
              <div>
                <b-img
                  :src="require('@/assets/images/pages/customer/email.svg')"
                  height="45"
                  rounded
                />
              </div>

              <div class="ml-1">
                <h4
                  v-b-tooltip.hover.v-secondary
                  :title="branchData.mail_api ? '已綁定' : '尚未綁定'"
                  class="d-flex align-items-center font-weight-bolder mr-25 mb-25 cursor-pointer"
                  @click="() => { $refs.adminApiMailExplorerModal.getData(mailApiInfo) }"
                >
                  信箱驗證
                  <b-img
                    :src="require('@/assets/images/pages/branch/lock-bind.svg')"
                    height="18"
                    rounded
                    class="ml-50"
                    style="margin-top: -5px;"
                    :style="{ 'opacity': branchData.mail_api ? 1 : 0.3 }"
                  />
                </h4>

                <small
                  :class="{'text-muted': !branchData.mail_api}"
                  class="show-text"
                >
                  {{ branchData.mail_api ? mailApiInfo.name : '檢查信箱是否可正常接收郵件' }}
                </small>
              </div>
            </div>

            <div v-if="!adminBranchState.isBusyLoading && !isBusy">
              <toggle-button
                v-model="branchData.mail_valid_switch"
                :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                :width="60"
                :height="24"
                class="m-25"
                :labels="{checked: '開啟', unchecked: '關閉'}"
                :sync="true"
              />
            </div>

            <div v-else>
              <b-img
                :src="$store.state.app.themeImages.loadingImg"
                rounded
                height="40"
                width="40"
              />
            </div>
          </div>
        </div>
      </b-card-body>

      <!-- 手機驗證 -->
      <b-card-body>
        <div class="kyc-card">
          <div class="d-flex justify-content-between flex-row w-100 align-items-center py-75">
            <div class="d-flex justify-content-start mr-1">
              <div>
                <b-img
                  :src="require('@/assets/images/pages/customer/security.svg')"
                  height="45"
                  rounded
                />
              </div>

              <div class="ml-1">
                <h4
                  v-b-tooltip.hover.v-secondary
                  title="尚未綁定"
                  class="d-flex align-items-center font-weight-bolder mr-25 mb-25 cursor-pointer"
                >
                  手機驗證
                  <b-img
                    :src="require('@/assets/images/pages/branch/lock-bind.svg')"
                    height="18"
                    rounded
                    class="ml-50"
                    style="margin-top: -5px;"
                    :style="{ 'opacity': '0.3' }"
                  />
                </h4>

                <small class="text-muted show-text">
                  確認手機號碼是否有效
                </small>
              </div>
            </div>

            <div v-if="!adminBranchState.isBusyLoading && !isBusy">
              <toggle-button
                v-model="branchData.phone_valid_switch"
                :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                :width="60"
                :height="24"
                class="m-25"
                :labels="{checked: '開啟', unchecked: '關閉'}"
                :sync="true"
              />
            </div>

            <div v-else>
              <b-img
                :src="$store.state.app.themeImages.loadingImg"
                rounded
                height="40"
                width="40"
              />
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <admin-api-mail-modal
      ref="adminApiMailExplorerModal"
      api-mail-explorer-id="adminApiMailExplorerModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @emit-hidden-function="() => {}"
      @call-back-data="callbackApiMailExplorer"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BImg, VBTooltip, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import { useBranchSetting, useBranchView } from '../../useBranch'
import AdminApiMailModal from '@/layouts/components/Modal/admin-api-mail-modal/apiMailModal.vue'

export default {
  components: {
    BCard,
    BImg,
    BCardHeader,
    BCardBody,

    ToggleButton,
    AdminApiMailModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    branchDataItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
  methods: {
    // (回調)選擇的API參數
    callbackApiMailExplorer(apiData) {
      this.branchData.mail_api = apiData.id
      this.mailApiInfo.id = apiData.id
      this.mailApiInfo.name = apiData.name
    },

    // (更新)
    submitBranchUpdate() {
      if (this.isBusy) return
      this.isBusy = true

      const resolveData = {
        branch_id: this.branchDataItem.id,
        data: {
          ...this.branchData,
        },
      }

      this.setBranchUpdate(resolveData, state => {
        if (state) this.useAlertToast(true, '更新分站成功')
        this.isBusy = false
      })
    },
  },
  setup(props) {
    const {
      ui,
      syncObject,
    } = useBranchSetting()

    const {
      isBusy,
      setBranchUpdate,
      useAlertToast,
      blankMailApiInfo,
    } = useBranchView()

    const blankBranchData = {
      mail_valid_switch: false,
      phone_valid_switch: false,
      mail_api: null,
    }

    const branchData = ref(JSON.parse(JSON.stringify(blankBranchData)))
    const mailApiInfo = ref(JSON.parse(JSON.stringify(blankMailApiInfo)))

    const resetBranchData = () => {
      if (isBusy.value) return
      const resolveBranchInfo = syncObject(blankBranchData, props.branchDataItem)
      const resolveMailApiInfo = syncObject(blankMailApiInfo, props.branchDataItem.mail_api_info)
      branchData.value = JSON.parse(JSON.stringify(resolveBranchInfo))
      mailApiInfo.value = JSON.parse(JSON.stringify(resolveMailApiInfo))
      isBusy.value = false
    }

    resetBranchData()

    return {
      ui,
      branchData,
      mailApiInfo,
      isBusy,
      setBranchUpdate,
      resetBranchData,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss" scoped>
.kyc-card {
  padding: 20px;
  border: 1px solid #b6b4bf47;
  border-radius: 10px;
  .kyc-card-btns {
    white-space: nowrap;
    .actions-link-btn-image {
      width: 24px;
      height: 24px;
    }
  }
}

.show-text {
  word-break: break-all;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.2;
  overflow: hidden;
}
</style>
