<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          基本設定
        </h4>
      </b-card-header>

      <b-card-body>
        <div class="ml-50">
          <!-- 分站名稱 -->
          <b-form-group
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            label="分站名稱"
            class="mb-25 preview-col-from-group"
          >
            <div class="mt-50 preview-col-label">
              {{ adminBranchState.branchInfo.name ? adminBranchState.branchInfo.name : '---' }}
            </div>
          </b-form-group>

          <!-- 分站代號 -->
          <b-form-group
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            label="代號"
            class="mb-25 preview-col-from-group"
          >
            <div class="mt-50 preview-col-label">
              {{ adminBranchState.branchInfo.branch ? adminBranchState.branchInfo.branch : '---' }}
            </div>
          </b-form-group>

          <!-- 網域 -->
          <b-form-group
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            label="網域"
            class="mb-25 preview-col-from-group"
          >
            <div class="mt-50 preview-col-label">
              {{ adminBranchState.branchInfo.domain ? adminBranchState.branchInfo.domain : '---' }}
            </div>
          </b-form-group>

          <!-- 訂單代號 -->
          <b-form-group
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            label="訂單代號"
            class="mb-25 preview-col-from-group"
          >
            <div class="mt-50 preview-col-label">
              {{ adminBranchState.branchInfo.prefix ? adminBranchState.branchInfo.prefix : '---' }}
            </div>
          </b-form-group>

          <!-- 說明 -->
          <b-form-group
            label-cols="4"
            label-cols-lg="5"
            label-cols-xl="4"
            label="說明"
            class="mb-25 preview-col-from-group"
          >
            <div class="mt-50 preview-col-label">
              {{ adminBranchState.branchInfo.introduce ? adminBranchState.branchInfo.introduce : '---' }}
            </div>
          </b-form-group>

        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BCardHeader, BCardBody, BFormGroup,
  // BFormInput, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-col-from-group {
  .preview-col-label {
    padding: 0 10px;
  }
}
</style>
