<template>
  <div class="branch-view-payment">
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-50">
        <h4>金流設定</h4>

        <div class="text-nowrap d-flex justify-content-end">
          <toggle-button
            v-model="isMonthlyLimit"
            :color="{checked: '#4683bb', unchecked: '#20c997'}"
            :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
            :width="60"
            :height="24"
            class="m-25 mb-50"
            :labels="{checked: '每月', unchecked: '每日'}"
            :sync="true"
          />
        </div>
      </div>

      <div v-if="!isBranchBusy">
        <div
          v-for="type in typeOptions"
          :key="type.value"
          class="mb-2"
        >
          <div v-if="branchPaymentData[type.value].length > 0">
            <div class="d-flex justify-content-start align-items-center">
              <div>
                <b-img
                  :src="require(`@/assets/images/pages/payment/${type.value}.svg`)"
                  height="35"
                  rounded
                />
              </div>

              <div class="ml-1">
                <h5 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                  {{ type.label }}
                </h5>
              </div>
            </div>

            <div class="mt-1 component-list border">
              <div
                v-for="(item, index) in branchPaymentData[type.value]"
                :key="index"
                class="component-area mb-1 border"
              >
                <div class="d-flex justify-content-between align-items-center component-area-content draggable-handle">
                  <div>
                    <div class="item-description font-weight-bold d-block text-nowrap">
                      {{ item.name }}
                      <!-- / {{ item.id }} -->
                    </div>

                    <small v-if="item.third_party_payment">
                      <div class="item-description text-muted">{{ item.third_party_payment.name }}</div>
                    </small>
                  </div>

                  <div class="d-flex align-items-center">
                    <!-- 單日限額 -->
                    <div
                      v-if="!isMonthlyLimit"
                      class="d-flex align-items-center table-col"
                    >
                      <div class="font-weight-bold text-body-heading mr-50 text-nowrap">
                        <span v-if="item.daily">{{ item.percentagedaily }} %</span>
                        <span v-else>不設限</span>
                      </div>
                      <vue-apex-charts
                        v-b-tooltip.hover.focus.v-secondary
                        :title="`${parseInt(item.daily_total, 10).toLocaleString()} / ${item.daily ? parseInt(item.daily, 10).toLocaleString() : '不設限'}`"
                        type="radialBar"
                        height="30"
                        width="30"
                        :options="item.chartdailyData.options"
                        :series="item.chartdailyData.series"
                      />

                    </div>

                    <!-- 單月限額 -->
                    <div
                      v-if="isMonthlyLimit"
                      class="d-flex align-items-center table-col"
                    >
                      <div class="font-weight-bold text-body-heading mr-50 text-nowrap">
                        <span v-if="item.monthly">{{ item.percentageMonthly }} %</span>
                        <span v-else>不設限</span>
                      </div>
                      <vue-apex-charts
                        v-b-tooltip.hover.focus.v-secondary
                        :title="`${parseInt(item.monthly_total, 10).toLocaleString()} / ${item.monthly ? parseInt(item.monthly, 10).toLocaleString() : '不設限'}`"
                        type="radialBar"
                        height="30"
                        width="30"
                        :options="item.chartMonthlyData.options"
                        :series="item.chartMonthlyData.series"
                      />

                    </div>
                  </div>
                </div>

                <hr>

                <div class="component-area-footer">
                  <div class="text-right">
                    <feather-icon
                      v-b-toggle="`collapse-list-${type.value}-${index}`"
                      icon="ChevronDownIcon"
                      size="12"
                    />
                  </div>

                  <b-collapse
                    :id="`collapse-list-${type.value}-${index}`"
                    class="mt-50"
                  >
                    <b-card no-body>
                      <b-table-simple
                        striped
                        class="table-sm"
                      >
                        <b-tr>
                          <b-td class="text-right align-top text-nowrap py-75">
                            驅動：
                          </b-td>
                          <b-td
                            class="py-75"
                            style="min-width: 100px;"
                          >
                            <span v-if="item.third_party_payment">{{ item.third_party_payment.provider }}</span>
                            <span v-else>---</span>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-right align-top text-nowrap py-75">
                            單筆上限：
                          </b-td>
                          <b-td class="py-75">
                            <span v-if="item.low">$ {{ parseInt(item.high, 10).toLocaleString() }}</span>
                            <span v-else>---</span>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-right align-top text-nowrap py-75">
                            單筆下限：
                          </b-td>
                          <b-td class="py-75">
                            <span v-if="item.low">$ {{ parseInt(item.low, 10).toLocaleString() }}</span>
                            <span v-else>---</span>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-card>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="branchData.payment.length === 0">
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="400"
              alt="查無資料"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex align-items-center justify-content-center my-5"
      >
        <b-img
          :src="$store.state.app.themeImages.loadingImg"
          rounded
          height="40"
          width="40"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BImg, VBTooltip, BTableSimple, BTr, BTd, VBToggle, BCollapse,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { ToggleButton } from 'vue-js-toggle-button'
import { useBranchView } from '../../useBranch'

export default {
  components: {
    BImg,
    BCard,
    BTableSimple,
    BCollapse,
    BTr,
    BTd,
    VueApexCharts,
    ToggleButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    branchDataItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    adminBranchState() {
      return this.$store.state['admin-branch']
    },
  },
  watch: {
    'adminBranchState.branchInfo': {
      handler() {
        this.initPaymentInfoList()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminBranchState.branchInfo) {
      this.initPaymentInfoList()
    }
  },
  methods: {
    // (初始化)
    initPaymentInfoList() {
      this.resetBranchData()
      this.resetBranchPaymentData()
      this.branchData.payment_info.forEach(item => {
        const paymentInfo = item
        const percentagedaily = paymentInfo.daily_total === 0 ? 0 : (paymentInfo.daily_total / paymentInfo.daily) * 100
        const roundedPercentagedaily = percentagedaily.toFixed(1)
        const percentageMonthly = paymentInfo.monthly_total === 0 ? 0 : (paymentInfo.monthly_total / paymentInfo.monthly) * 100
        const roundedPercentageMonthly = percentageMonthly.toFixed(1)

        const resolve = {
          ...paymentInfo,
          chartdailyData: JSON.parse(JSON.stringify(this.blankChartData)),
          chartMonthlyData: JSON.parse(JSON.stringify(this.blankChartData)),
          percentagedaily: roundedPercentagedaily,
          percentageMonthly: roundedPercentageMonthly,
        }

        resolve.chartdailyData.series[0] = resolve.percentagedaily
        resolve.chartMonthlyData.series[0] = resolve.percentageMonthly

        if (paymentInfo.type === 'account') {
          this.branchPaymentData.account.push(resolve)
        } else if (paymentInfo.type === 'store') {
          this.branchPaymentData.store.push(resolve)
        } else if (paymentInfo.type === 'credit') {
          this.branchPaymentData.credit.push(resolve)
        }
      })
      this.isBranchBusy = false
    },
  },
  setup(props) {
    const {
      syncObject,
      useAlertToast,
      blankChartData,
    } = useBranchView()

    const blankBranchData = {
      payment: [],
      payment_info: [],
    }
    const blankBranchPaymentData = {
      account: [],
      store: [],
      credit: [],
    }

    const typeOptions = [{
      label: '虛擬帳號', value: 'account',
    }, {
      label: '信用卡', value: 'credit',
    }, {
      label: '超商代碼', value: 'store',
    }]

    const branchData = ref(JSON.parse(JSON.stringify(blankBranchData)))
    const branchPaymentData = ref(JSON.parse(JSON.stringify(blankBranchPaymentData)))

    const isBranchBusy = ref(true)
    const isMonthlyLimit = ref(false)

    const resetBranchData = () => {
      const resolveBranchInfo = syncObject(blankBranchData, props.branchDataItem)
      branchData.value = JSON.parse(JSON.stringify(resolveBranchInfo))
    }

    const resetBranchPaymentData = () => {
      branchPaymentData.value = JSON.parse(JSON.stringify(blankBranchPaymentData))
    }

    return {
      blankChartData,
      isBranchBusy,
      isMonthlyLimit,
      branchData,
      branchPaymentData,
      useAlertToast,
      typeOptions,
      resetBranchData,
      resetBranchPaymentData,
    }
  },
}
</script>

<style lang="scss" scoped>
.branch-view-payment {
  .actions-link-btn-image {
    width: 20px;
    height: 20px;
  }

  .component-list {
    padding: 15px;
    border-radius: 10px;
  }

  .component-area {
    background-color: #b5bfd634;
    border-radius: 10px;
    .component-area-content {
      padding: 15px;
    }

    hr {
      border-color: #cfcfcf57;
      margin: 0;
    }

    .component-area-footer {
      padding: 5px 15px;
      cursor: auto;
    }
  }
}
</style>
